import styled from 'styled-components';

import colors from '../../styles/colors';

const { white, black, darkgrey } = colors;

export const Container = styled.div`
  background: ${white};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h4`
  margin-bottom: 0;
  color: ${black};
  text-align: center;
`;

export const Text = styled.p`
  color: ${darkgrey};
`;

export const Logo = styled.img`
  height: auto;
  width: 250px;
  align-self: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;
