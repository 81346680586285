import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import * as Pages from './pages';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/about' />
      <Route
        component={Pages.ChangePassword}
        exact
        path='/reset-password/:token'
      />
      <Route component={Pages.VerifyEmail} path='/verified-email' />
      <Route component={Pages.PrivacyPolicy} path='/privacy-terms' />
      <Route component={Pages.TermsOfUse} path='/terms-of-use' />
      <Route
        component={Pages.PasswordChangeSucess}
        path='/reset-password-success'
      />
      <Route component={Pages.InvalidToken} path='/invalid-authentication' />
      <Route
        component={Pages.Redirect}
        exact
        path='/post/:type/:profileId/:postId'
      />
      <Route component={Pages.Redirect} exact path='/pet-tag/:tagId' />
      <Route component={Pages.Redirect} exact path='/place/:placeId' />
      <Route component={Pages.Redirect} exact path='/about' />
      {/* <Redirect to='/' /> */}
    </Switch>
  );
};

export default Routes;
