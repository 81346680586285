import React from 'react';
import { Router } from 'react-router-dom';

import Routes from './Routes';

import { store } from './store';
import { Provider } from 'react-redux';

import history from './services/history';

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>
);

export default App;
