export default {
  primary: '#FF6600',
  yellow: '#F9C80E',
  purple: '#662E9B',
  lightblue: '#43BCCD',
  blue: '#A9DEF9',
  steelblue: '#4389BC',
  red: '#EA3546',
  pink: '#FF99C8',
  turquoise: '#007AFF',

  lightgrey: '#F9F9F9',
  greyish: '#C9C9C9',
  grey: '#E9E9E9',
  darkgrey: '#707070',

  black: '#333333',
  white: '#FFFFFF',
  tabwhite: '#f5f6fa',
};
