import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Header, Loading } from '../../components';

import { ButtonRedirect, Container } from './styles';
import constants from '../../config/constants';
import { useTranslation } from 'react-i18next';
import {
  getRedirectPetTagRoute,
  getRedirectPetTagWebsite,
  getRedirectPlaceRoute,
  getRedirectPostRoute,
} from './helpers';

const Redirect: React.FC = () => {
  const params = useParams<any>();
  const location = useLocation();

  const path = location.pathname.split('/')[1];
  const { t, i18n } = useTranslation();

  const redirectRoute = useMemo(() => {
    switch (path) {
      case 'post':
        return getRedirectPostRoute(params);
      case 'pet-tag':
        return getRedirectPetTagRoute(params);
      case 'place':
        return getRedirectPlaceRoute(params);
      default:
        return constants.siteUrl;
    }
  }, [path, params]);

  useEffect(() => {
    if (!redirectRoute) return;
    window.location.href = redirectRoute;
    const timeoutRef = setTimeout(function () {
      if (path === 'pet-tag') {
        const { tagId } = params;
        if (tagId === undefined) {
          window.location.href = constants.dynamicLink;
          return;
        }
        const { language } = i18n;
        const isPt = language === 'pt-BR';
        window.location.href = getRedirectPetTagWebsite(params, isPt);
      } else {
        window.location.href = constants.dynamicLink;
      }
    }, 2000);
    return () => clearTimeout(timeoutRef);
  }, [redirectRoute]);

  return (
    <Container>
      <Header
        title={t('TITLE_REDIRECT')}
        description={t('DESCRIPTION_REDIRECT')}
      />
      {/* <ButtonRedirect href={constants.dynamicLink}>
        {t('BUTTON_TEXT_REDIRECT')}
      </ButtonRedirect> */}
      <Loading />
    </Container>
  );
};

export default Redirect;
