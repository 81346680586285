export const TRANSLATIONS_EN = {
  TITLE_REDIRECT: 'Hey! Almost there...',
  DESCRIPTION_REDIRECT: 'We are redirecting you. Wait a few seconds.',
  BUTTON_TEXT_REDIRECT: 'Download now',

  YUP_PASSWORD_REQUIRED: 'The password must be at least 6 characters long.',
  YUP_GENERAL_REQUIRED: 'Fill in this field.',
  YUP_PASSWORD_CONFIRM: 'Passwords do not match',

  CHANGE_PASSWORD: 'Recover password',
  CHANGE_PASSWORD_PLACEHOLDER: 'Enter your new password',
  CHANGE_PASSWORD_PLACEHOLDER_CONFIRM: 'Confirm your new password',

  INVALID_TOKEN_TITLE: 'Oops! You are not authenticated.',
  INVALID_TOKEN_DESCRIPTION: 'Please try again later.',

  SUCESS_PASSWORD_CHANGE_SUCESS: 'Password changed successfully!',

  VERIFY_EMAIL_TITLE: 'Verified account.',
  VERIFY_EMAIL_DESCRIPTION: 'Woohoo! Now you can start sharing your stories',

  PRIVACY_POLICY: `PRIVACY POLICY
  <br />
  PetLog prioritizes the privacy and security of our users personal data, thus, we always seek to maintain transparency when it comes to the treatment of this data.
  <br />
  For this, we provide this PRIVACY POLICY to clarify how the collection, use and transfer of data to users and support platforms is carried out, thus making your experience increasingly satisfactory.
  <br />
  By using our platform (PetLog), you understand that we will collect and use your data within the legal compliance provided for in the Brazilian Federal Constitution (article 5, item LXXIX), as well as in the Brazilian General Data Protection Law (Law 13.709/2018) and, where applicable, the other applicable legal rules.

  Thus, PetLog is bound by the provisions of this document.
  <br />
  1 - What information is captured?
  <br />
  In order to provide you with a unique and complete experience, we collect the content, communications and other information you provide to us when you use our platform, including when you register to create a PetLog account; When you create or share content; when you send messages or communicate with others. This may include information on or about the content you provide, such as the location of a photo or the date the file was created.
  <br />
  The information concerns: Name, date of birth, gender, city, Pet's name, Pet's breed, Pet's age.
  <br />
  We also capture your location, even with the application running in the background, in order to be able to more accurately trace the route of your walk, a route that can be shared on the platform by the user with his/her free consent.
  <br />
  2 - How is this data captured?
  <br />
  We use integrations with systems from other companies, outside our own system, in order to guarantee the best service for our users.
  <br />
  Data is captured when registering on the platform and while using the application.
  <br />
  3 - From whom are they captured?
  <br />
  Information from users who have created an account on the platform is captured (full name, phone, username, email, gender, password, date of birth, location, biography, photos and videos). Initially, these users are individuals, and may also be establishments.
  <br />
  Information about the animals registered on the platform is also captured (name, breed, sex, age, photos and videos).
  <br />
  4 - What is the purpose of capturing this data?
  <br />
  The data is captured in order to be able to provide the platform user with an experience and content that is of their interest/taste, indicating similar profiles, walking areas, etc. Thus, you will have a personalized profile according to your interests.
  <br />
  Regarding the location, we use the information related to the location to be able to trace the route of your walk as reliably as possible (when the functionality is active), even when the application is running in the background.
  <br />
  5 - Is there sharing of such information? If yes, what is the purpose?
  <br />
  Yes, information is shared with third-party providers. For example, your geolocation is shared, since we have integration with Google maps in which we use this information to know the location of our users. We have integration with Mapbox to generate maps of users' walks. We use Amazon's servers and database through their services like AWS. The One Signal integration is used to send notification messages to our users.
  <br />
  There's also sharing with people and accounts that you share and communicate with directly because once you communicate with those people, they can see the content of what you send.
  <br />
  Public information (public posts, username, pets registered by the pet owner) can be seen by anyone within the platform, thus allowing you to know if that user has the same interests as yours.
  <br />
  In addition to the above cases, data may be shared in cases of legal determination from competent judicial, administrative or governmental authorities, changes in the corporate structure (deletion, merger or acquisition) and for the protection of any type of conflict involving PetLog, including judicial issues.
  <br />
  6 - How long is my data kept on the platform?
  <br />
  In general, your data will be kept on the platform during the entire period of its use, being deleted within a period of 180 (one hundred and eighty) calendar days after the account deletion request, except in the cases of article 16 of the Brazilian General Law. of Data Protection - LGPD.
  <br />
  7 - What are the information security policies implemented in the company?
  <br />
  In all integrations we use " https:// " security protocols to encrypt information exchanges. We are constantly improving and seeking to implement good information security practices so that we are always aware of technological innovations.
  <br />
  The user can still request the deletion of their account along with their collected data, which will be answered. This deletion will take place within a period of 180 (one hundred and eighty) calendar days in case the user decides to use our platform again and, after this period, the data will be permanently deleted.
  <br />
  8 - What about law enforcement or legal requests?
  <br />
  We share information with law enforcement authorities if solicited or in the event of responses to legal requests duly recognized in court.
  <br />
  9 - How do I change or delete information about myself?
  <br />
  Petlog gives you the opportunity to access, rectify or delete your data, having a period of 180 (one hundred and eighty) days for total and permanent deletion from the platform's database.
  <br />
  10 - What happens if I choose to delete my account?
  <br />
  In the event of an account deletion request, your data will still be kept for 180 (one hundred and eighty) consecutive days and, after that period, will be permanently deleted from the platform's database. Thus, all your publications (photos, videos, walk history, private conversations, etc.) and activities (likes and comments) will be deleted, and it is not possible to recover this information.
  <br />
  11 - How will we notify you in case of a change in this policy?
  <br />
  We will notify you in advance when the Privacy Policy changes, giving you the opportunity to review it before deciding to continue using the platform.
  <br />
  12 - How to contact us in case of doubts?
  <br />
  You can contact us through the available communication channels (Instagram, email, website, etc).
  `,

  TERMS_OF_USE: `TERMS OF USE OF THE PETLOG PLATFORM
  <br />
  Welcome to PetLog! The following Terms of Use are the parameters that will indicate the guidelines for using this platform, informing the services offered and the features made available.

  PetLog will initially only be available through an application for smartphones with IOS and Android systems.

  By using or creating an account at PETLOG, you fully agree with these Terms, the same being valid as a Binding Agreement between the platform and the user.
  <br />
  1 - THE PLATFORM
  <br />
  1.1 - This platform is a digital environment aimed at pet owners and their pets in order to create an environment for sharing and interaction between users through the features inherent to social networks, namely: messages, photos, videos , comments, likes, etc.
  <br />
  1.2 - The user will share with his friends and users of the platform, photos (temporary and permanent), videos (temporary and permanent), texts, walks (sharing geolocation data, date, time, walk time and route, being able to share images) , and you can also create/organize virtual "meetings and events" to which you can join.
  <br />
  2 - REGISTRATION OF USERS AND THEIR PETS
  <br />
  2.1 - The registration of users will only be confirmed if they fill in all the mandatory/indispensable fields for registration on the PetLog platform.
    <br />
    2.1.1 - User registration will be carried out in two steps: the first step with the registration of the PET OWNER user and the second step for the registration of their respective Pet(s).
    <br />
    2.1.2 - All information provided must be truthful, accurate, and reliable information (full name, username, password, e-mail, date of birth, city, federative unit, etc). The same parameters apply to the registration of the pet (name, date of birth, gender, race, etc.), which will be linked directly to its guardian.
    <br />
    2.1.3 - It is the user's responsibility to update their registration on PetLog.
    <br />
    2.1.4 - In the event of incorrect or untrue data being informed at the time of registration, PetLog reserves the right to permanently or temporarily exclude or block the registration until the errors that caused the sanction are resolved.
    <br />
    2.1.5 - The data informed in the registration cannot be disclosed to third parties, being the user's sole responsibility to maintain the secrecy of his username and password.
  <br />
  2.2 - After a successful registration, the User will be able to enjoy all the services offered by the platform by accessing the application with the username and password informed at the time of registration.
  <br />
  3 - USER OBLIGATIONS
  <br />
  3.1 - Users are obliged to accept these TERMS OF USE and their conditions in their entirety in order to have access to the platform.
  <br />
  3.2 - All users are obliged to maintain a good relationship with each other, valuing the respect and integrity of other users and the platform, under penalty of failing to do so, having their registration excluded from the platform, without prejudice to other civil, criminal responsibilities and/or administrative actions that may be taken.
  <br />
  3.3 - Users are strictly prohibited from advertising products prohibited by current legislation or by the competent regulatory bodies, as well as those that violate the rights of third parties, imply the practice of an illegal act and violate current legislation.
  <br />
  3.4 - Users are fully responsible for the data and information published.
  <br />
  3.5 - Users are responsible for respecting their current countries legislation and the provisions of this Platform Use Term.
  <br />
  4- OBLIGATIONS OF PetLog
  <br />
  4.1 - PetLog is obliged to grant access to all the features of the platform to users who fill in all the mandatory fields with true information at the time of registration.
  <br />
  4.2 - PetLog is obliged to protect, through storage on servers or data encryption technologies, all information provided by its users.
    <br />
    4.2.1 - PetLog will not be responsible for repairing moral or material damages caused by the capture or seizure of data in bad faith by third parties.
    <br />
    4.2.2 - PetLog will not be responsible for publishing content that does not comply with the platform guidelines that may affect users or third parties, directly or indirectly.
  <br />
  5 - MODIFICATIONS OF THESE TERMS OF USE
  <br />
  5.1 - PetLog may, at any time, change/modify/update the provisions of this Platform's Terms of Use, with the purpose of constantly improving its provisions/guidelines, amending legislation or features available on the platform, implementing new technologies or whenever, at PetLog's sole discretion, such changes are necessary, provided that it communicates to its users through the application, and the user may or may not agree with the new Terms of Use.
    <br />
    5.1.1 - If the user disagrees with the new Terms of Use, the user may contact PetLog through the communication channels made available to them.
    <br />
    5.1.2 - In case of non-acceptance of the new Terms of Use of the Platform, the user will have their access interrupted indefinitely until the acceptance of the new terms.
    <br />
    5.2 - The modifications made to this Platform Use Term will take effect on the date of its implementation.
  <br />
  6 - GENERAL PROVISIONS
  <br />
  6.1 - Posts published by users are restricted to the use of texts and images.
  <br />
  7 - ACCEPTANCE OF THE TERMS OF USE OF THE PETLOG PLATFORM
  <br />
  7.1 - The user declares to have read and accepted, in their entirety, the guidelines, conducts and provisions contained in this TERMS OF USE OF THE PETLOG PLATFORM.
  <br />
  8 - JURISDICTION
  <br />
  8.1 - To settle any and all disputes arising from the terms set out in these TERMS OF USE OF THE PETLOG PLATFORM, they will be resolved in accordance with current Brazilian legislation, with the jurisdiction of the city of Natal, State of Rio Grande do Norte.
  `,
};
