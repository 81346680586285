import styled from 'styled-components';
import colors from '../../styles/colors';

const { white, turquoise } = colors;
export const Container = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const ButtonRedirect = styled.a`
  width: 250px;
  height: 40px;
  border: none;
  display: flex;
  color: ${white};
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  background: ${turquoise};
  :focus {
    outline: 0;
  }
`;
