import styled from 'styled-components';

import colors from '../../styles/colors';

const { turquoise } = colors;

export const BackgroundDiv = styled.div`
  z-index: 100;
  display: flex;
  justify-content: center;

  .loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: 50;
  }
  .loader div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${turquoise};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loader div:nth-child(1) {
    left: 6px;
    animation: loader1 0.6s infinite;
  }
  .loader div:nth-child(2) {
    left: 6px;
    animation: loader2 0.6s infinite;
  }
  .loader div:nth-child(3) {
    left: 26px;
    animation: loader2 0.6s infinite;
  }
  .loader div:nth-child(4) {
    left: 45px;
    animation: loader3 0.6s infinite;
  }
  @keyframes loader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes loader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`;
