import styled from 'styled-components';
import { Field } from 'formik';
import colors from '../../styles/colors';

const { white, darkgrey, greyish, turquoise, red } = colors;

export const Container = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const InputsContainer = styled.div`
  width: 250px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;

  .error {
    color: ${red};
    font-size: 12px;
    margin: 5px 0 5px -10px;
    align-self: flex-start;
  }
`;

export const Input = styled(Field)`
  width: 100%;
  height: 20px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: ${white};
  border: ${greyish} 1px solid;

  ::placeholder {
    color: ${darkgrey};
  }
  :focus {
    outline-style: none;
    outline-color: transparent;
    border: ${turquoise} 1px solid;
  }
`;

export const Button = styled.button`
  border: none;
  height: 40px;
  color: ${white};
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  text-decoration: none;
  background: ${turquoise};

  :focus {
    outline: 0;
  }
`;
