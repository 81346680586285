import { Formik, Form, ErrorMessage } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Header, Loading } from '../../components';
import { useSelector } from '../../hooks';
import { AccountActions } from '../../store/account';
import { EditCredentials } from '../../store/account/types';

import formSchema from './formSchema';
import { Container, InputsContainer, Input, Button } from './styles';

interface Params {
  token: string;
}

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useParams<Params>();
  const { t } = useTranslation();

  const autenticationLoading = useSelector(
    (state) => state.account.autenticationLoading
  );

  function handleSubmit(values: EditCredentials) {
    dispatch(AccountActions.changePasswordRequest(values.password));
  }

  useEffect(() => {
    dispatch(AccountActions.validateTokenRequest(token));
    // eslint-disable-next-line
  }, [token]);

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  if (autenticationLoading)
    return (
      <Container>
        <Loading />
      </Container>
    );


  return (
    <Container>
      <Header title={t("CHANGE_PASSWORD")} />

      <Formik onSubmit={handleSubmit} {...formSchema}>
        <Form onKeyDown={onKeyDown}>
          <InputsContainer>
            <Input
              name='password'
              type='password'
              placeholder={t("CHANGE_PASSWORD_PLACEHOLDER")}
            />
            <ErrorMessage component='div' className='error' name='password' />

            <Input
              name='confirmPassword'
              type='password'
              placeholder={t("CHANGE_PASSWORD_PLACEHOLDER_CONFIRM")}
            />
            <ErrorMessage
              component='div'
              className='error'
              name='confirmPassword'
            />

            <Button type='submit'>{t("CHANGE_PASSWORD")}</Button>
          </InputsContainer>
        </Form>
      </Formik>
    </Container>
  );
};

export default ChangePassword;
