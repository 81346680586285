import constants from '../../config/constants';

export interface RedirectPostParams {
  type: string;
  profileId: string;
  postId: string;
}

export interface RedirectPetTagParams {
  tagId: string;
}

export interface RedirectPlaceParams {
  placeId: string;
}

const buildAppRedirectRoute = (customPath: string) => {
  return `${constants.appScheme}${customPath}`;
};

const getRedirectPostRoute = ({
  type,
  profileId,
  postId,
}: RedirectPostParams) => {
  return buildAppRedirectRoute(`post/${type}/${profileId}/${postId}`);
};

const getRedirectPetTagRoute = ({ tagId }: RedirectPetTagParams) => {
  return buildAppRedirectRoute(`pet-tags/${tagId}`);
};

const getRedirectPlaceRoute = ({ placeId }: RedirectPlaceParams) => {
  return buildAppRedirectRoute(`place/${placeId}`);
};

const getRedirectPetTagWebsite = (
  { tagId }: RedirectPetTagParams,
  isPt: boolean
) => {
  return `${constants.petTagSiteUrl}/${isPt ? 'pt-br' : 'en'}/pet-tag/${tagId}`;
};

export {
  getRedirectPostRoute,
  getRedirectPetTagRoute,
  getRedirectPlaceRoute,
  getRedirectPetTagWebsite,
};
