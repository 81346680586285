import client from '../client';

function validateToken(token: string) {
  return client('account-tokens')
    .remoteMethod('validate')
    .param('token', token)
    .get();
}

function changePassword(data, token: string) {
  return client('accounts')
    .remoteMethod('reset-password')
    .data(data)
    .headers({ Authorization: token })
    .post();
}

export default {
  validateToken,
  changePassword,
};
