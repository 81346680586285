import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../components';

const PasswordChangeSucess: React.FC = () => {
  const { t } = useTranslation();

  return <Header title={t("SUCESS_PASSWORD_CHANGE_SUCESS")} />;
};

export default PasswordChangeSucess;
