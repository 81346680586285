import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import colors from '../../styles/colors';

const PrivacyPolice: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        textAlign: 'justify',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: colors.white,
      }}
    >
      <Trans t={t}>PRIVACY_POLICY</Trans>
    </div>
  );
};

export default PrivacyPolice;
