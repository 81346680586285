import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import ApplicationState from '../store/types';

const useSelector: TypedUseSelectorHook<ApplicationState> = useReduxSelector;

export default useSelector;
