import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../components';

const PasswordChangeSucess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Header
      title={t("INVALID_TOKEN_TITLE")}
      description={t("INVALID_TOKEN_DESCRIPTION")}
    />
  );
};

export default PasswordChangeSucess;
