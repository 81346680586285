/** Action Types */

export enum AccountTypes {
  VALIDATE_TOKEN_REQUEST = '@account/VALIDATE_TOKEN_REQUEST',
  VALIDATE_TOKEN_SUCCESS = '@account/VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_FAILURE = '@account/VALIDATE_TOKEN_FAILURE',

  CHANGE_PASSWORD_REQUEST = '@account/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = '@account/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = '@account/CHANGE_PASSWORD_FAILURE',
}

export interface ChangePasswordAction {
  type: AccountTypes.CHANGE_PASSWORD_REQUEST;
  payload: { newPassword: string };
}

export interface ValidateTokenAction {
  type: AccountTypes.VALIDATE_TOKEN_REQUEST;
  payload: { token: string };
}

export type AccountActionTypes = ChangePasswordAction | ValidateTokenAction;

/** State Type  */

export interface AccountState {
  token: string;
  autenticationLoading: boolean;
  changePasswordLoading: boolean;
}

/** Types */

export interface EditCredentials {
  password: string;
  confirmPassword?: string;
}
