import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en-US";
import { TRANSLATIONS_PT } from "./pt-BR";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     "en": {
       translation: TRANSLATIONS_EN
     },
     "pt": {
       translation: TRANSLATIONS_PT
     },
     "pt_BR": {
      translation: TRANSLATIONS_PT
    }
   },
   fallbackLng: 'en'
 });
 i18n.changeLanguage()
