import React from 'react';

import { BackgroundDiv } from './styles';

const Loading: React.FC = () => {
  return (
    <BackgroundDiv>
      <div className='loader'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </BackgroundDiv>
  );
};

export default Loading;
