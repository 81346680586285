import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../components';

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Header
      title={t("VERIFY_EMAIL_TITLE")}
      description={t("VERIFY_EMAIL_DESCRIPTION")}
    />
  );
};

export default VerifyEmail;
