import { takeLatest, call, put } from 'redux-saga/effects';
import { ErrorType } from '../../@types/general';
import * as api from '../../services/api';
import history from '../../services/history';
import * as AccountActions from './actions';
import * as selectors from '../selectors';

import {
  AccountTypes,
  ChangePasswordAction,
  ValidateTokenAction,
} from './types';

function* changePassword({ payload: { newPassword } }: ChangePasswordAction) {
  const { token } = yield selectors.getAccount();
  try {
    yield call(api.account.changePassword, { newPassword }, token);

    yield changePasswordSuccess();
  } catch (err) {
    yield changePasswordFailure(err);
  }
}

function* changePasswordSuccess() {
  yield put(AccountActions.changePasswordSuccess());
  history.push('/reset-password-success');
}

function* changePasswordFailure(err: ErrorType) {
  console.log(err);
  yield put(AccountActions.changePasswordFailure());
}

function* validateToken({ payload: { token } }: ValidateTokenAction) {
  const { data } = yield call(api.account.validateToken, token);

  if (data.isValid) {
    yield validateTokenSuccess(token);
  } else {
    yield put(AccountActions.validateTokenFailure());
    history.push('/invalid-authentication');
  }
}

function* validateTokenSuccess(token: string) {
  yield put(AccountActions.validateTokenSuccess(token));
}

export default [
  takeLatest(AccountTypes.VALIDATE_TOKEN_REQUEST, validateToken),
  takeLatest(AccountTypes.CHANGE_PASSWORD_REQUEST, changePassword),
];
