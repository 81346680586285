import { action } from 'typesafe-actions';

import { AccountTypes, AccountActionTypes } from './types';

export function changePasswordRequest(newPassword: string): AccountActionTypes {
  return action(AccountTypes.CHANGE_PASSWORD_REQUEST, { newPassword });
}

export function changePasswordSuccess() {
  return action(AccountTypes.CHANGE_PASSWORD_SUCCESS);
}

export function changePasswordFailure() {
  return action(AccountTypes.CHANGE_PASSWORD_FAILURE);
}

export function validateTokenRequest(token: string) {
  return action(AccountTypes.VALIDATE_TOKEN_REQUEST, { token });
}

export function validateTokenSuccess(token: string) {
  return action(AccountTypes.VALIDATE_TOKEN_SUCCESS, { token });
}

export function validateTokenFailure() {
  return action(AccountTypes.VALIDATE_TOKEN_FAILURE);
}
