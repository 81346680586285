import { Reducer } from 'redux';

import * as Actions from './actions';
import { AccountState, AccountTypes } from './types';

const INITIAL_STATE: AccountState = {
  token: '',
  autenticationLoading: false,
  changePasswordLoading: false,
};

const reducer: Reducer<AccountState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AccountTypes.VALIDATE_TOKEN_REQUEST:
      return { ...state, token: '', autenticationLoading: true };

    case AccountTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        autenticationLoading: false,
      };

    case AccountTypes.VALIDATE_TOKEN_FAILURE:
      return { ...state, autenticationLoading: false };

    case AccountTypes.CHANGE_PASSWORD_REQUEST:
      return { ...state, changePasswordLoading: true };

    case AccountTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordLoading: false };

    case AccountTypes.CHANGE_PASSWORD_FAILURE:
      return { ...state, changePasswordLoading: false };

    default:
      return state;
  }
};

export const AccountActions = Actions;
export default reducer;
