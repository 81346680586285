export const TRANSLATIONS_PT = {
  TITLE_REDIRECT: 'Opa! Quase lá...',
  DESCRIPTION_REDIRECT: 'Estamos redirecionando você. Aguarde alguns segundos.',
  BUTTON_TEXT_REDIRECT: 'Baixe agora',

  YUP_PASSWORD_REQUIRED: 'A senha deve ter no mínimo 6 caracteres.',
  YUP_GENERAL_REQUIRED: 'Preencha esse campo.',
  YUP_PASSWORD_CONFIRM: 'Por favor, insira a mesma senha',

  CHANGE_PASSWORD: 'Recuperar senha',
  CHANGE_PASSWORD_PLACEHOLDER: 'Digite sua nova senha',
  CHANGE_PASSWORD_PLACEHOLDER_CONFIRM: 'Confirmar sua nova senha',

  INVALID_TOKEN_TITLE: 'Oops! Você não está autenticado.',
  INVALID_TOKEN_DESCRIPTION: 'Tente novamenta mais tarde.',

  SUCESS_PASSWORD_CHANGE_SUCESS: 'Senha alterada com sucesso!',

  VERIFY_EMAIL_TITLE: 'Conta verificada.',
  VERIFY_EMAIL_DESCRIPTION:
    'Uhuuuu! Agora você pode começar a compartilhar suas histórias',
  PRIVACY_POLICY: `POLITICA DE PRIVACIDADE
  <br />
  A PetLog tem como prioridade a privacidade e segurança dos dados pessoais de nossos usuários, assim, buscamos sempre manter a transparência quando se diz respeito ao tratamento desses dados.
  Para isso, disponibilizamos a presente POLÍTICA DE PRIVACIDADE para esclarecer como é feita essa coleta de dados, uso e transferência para os usuários e as plataformas de suporte, deixando assim sua experiência cada vez mais satisfatória.
  Ao utilizar nossa plataforma (PetLog), você compreende que coletaremos e utilizaremos seus dados dentro das conformidades legais previstas em nossa Constituição Federal (artigo 5º, inciso LXXIX), bem como na Lei Geral de Proteção de Dados (Lei 13.709/2018) e, no que couber, as demais normas jurídicas aplicáveis.
  Assim, a PetLog obriga-se ao disposto neste documento.
  <br />
  1 - Que informações são capturadas?
  <br />
    No intuito de oferecer uma experiência única e completa, coletamos o conteúdo, comunicações e outras informações que você nos fornece quando utiliza nossa plataforma, inclusive quando você se cadastra para criar uma conta PetLog; Quando cria ou compartilha conteúdo; quando envia mensagens ou se comunica com outras pessoas. Isso poderá incluir informações presentes ou sobre o conteúdo que você fornece, como a localização de uma foto ou data em que o arquivo foi criado.
    <br />
    As informações diz respeito a: Nome, data de nascimento, sexo, cidade, nome do Pet, raça do Pet, idade do Pet.
    <br />
  Capturamos ainda a sua localização, mesmo com o aplicativo rodando em segundo plano, para poder traçar com maior exatidão o percurso do passeio, percurso esse que pode ser compartilhado na plataforma pelo próprio usuário à seu livre consentimento.
  <br />
  2 -  Como são capturados esses dados?
  <br />
    Utilizamos integrações com sistemas de outras empresas, fora o nosso próprio sistema, com o intuito de garantir o melhor serviço para os nossos usuários.
  Os dados são capturados no ato do cadastramento na plataforma e, durante o uso do aplicativo.
  <br />
  3 - De quem são capturadas?
  <br />
    São capturadas informações de usuários que criaram conta na plataforma (nome completo, telefone, nome de usuário, e-mail, sexo, senha, data de nascimento, localização, biografia, fotos e vídeos). Inicialmente esses usuários são pessoas físicas, podendo ser também estabelecimentos.
    <br />
    São capturadas ainda informações acerca dos animais cadastrados na plataforma (nome, raça, sexo, idade, fotos e vídeos).
  <br />
  4 - Qual a finalidade da captura desses dados?
  <br />
    Os dados são capturados com o intuito de poder fornecer ao usuário da plataforma uma experiência e conteúdos que sejam de seu interesse/gosto, indicando perfis semelhantes, áreas de passeio, etc. Assim, você terá um perfil personalizado conforme seus interesses.
    <br />
    Com relação a localização, utilizado as informações relacionadas a localização para poder traçar da forma mais fidedigna possível o trajeto de seu passeio (quando a funcionalidade estiver ativa), inclusive quando o aplicativo estiver rodando em segundo plano.
  <br />
  5 - Há o compartilhamento de tais informações? Se sim, qual a finalidade?
  <br />
  Há sim o compartilhamento de informações com fornecedores terceiros. A exemplo, é compartilhado a sua geolocalização, uma vez que temos integração com o Google maps no qual utilizamos essa informação para saber a localização dos nossos usuários. Temos integração com o Mapbox para para gerar mapas dos passeios dos usuários. Usamos os servidores e banco de dados da Amazon através dos serviços deles como AWS. A integração da One Signal é utilizado para enviar   	as mensagens de notificações para os nossos usuários.
  <br />
  Há ainda o compartilhamento com pessoas e contas com que você compartilha e se comunica diretamente pois, uma vez que você se comunica com essas pessoas, elas podem ver o conteúdo do que você envia.
  <br />
  As informações públicas (publicações públicas, nome de usuário, pets cadastrados pelo tutor) podem ser vistas por qualquer pessoa dentro da plataforma, dessa forma, possibilita a você saber se aquele usuário tem os mesmos interesses que os seus.
  <br />
  Além das hipóteses acima, poderão ser compartilhados dados em casos de determinação legal oriundas de autoridades judiciais, administrativas ou governamentais competentes, alteração no quadro societário (exclusão, fusão ou aquisição) e para a proteção de qualquer tipo de conflito que envolva a PetLog, inclusive questões judiciais.
  <br />
  6 - Por quanto tempo meus dados são mantidos na plataforma?
    De forma geral, seus dados serão mantidos na plataforma durante todo o período de sua utilização, sendo excluído dentro de um prazo de 180 (cento e oitenta) dias corridos após o pedido de exclusão da conta, ressalvadas as hipóteses do artigo 16 da Lei Geral de Proteção de Dados - LGPD.
  <br />
  7 - Quais as políticas de segurança da informação implementadas na empresa?
  <br />
    Em todas as integrações nós utilizamos os protocolos de segurança " https:// "para criptografar as trocas de informações. Constantemente estamos aprimorando e buscando a implementação de boas práticas de segurança da informação para estarmos sempre a par das inovações tecnológicas.
  Ainda pode o usuário solicitar a exclusão de sua conta junto com seus dados coletados, o que será atendido. Essa exclusão se dará em um prazo de 180 (cento e oitenta) dias corridos para o caso do usuário resolver voltar a utilizar nossa plataforma e, depois desse período, os dados serão excluídos permanentemente.
  <br />
  8 - E com relação a aplicação da lei ou solicitações legais?
  <br />
    Compartilhamos informações com autoridades responsáveis pela aplicação da Lei ou em caso de respostas a solicitações legais devidamente reconhecidas em juízo.
  <br />
  9 - Como faço para alterar ou excluir informações sobre mim?
  <br />
    A Petlog dá a oportunidade de você poder acessar, retificar ou apagar seus dados, tendo um prazo de 180 (cento e oitenta) dias para exclusão total e permanente da base de dados da plataforma.
  <br />
  10 - O que acontece se eu optar por excluir minha conta?
  <br />
    Em caso de solicitação de exclusão de conta, seus dados serão mantidos ainda por 180 (cento e oitenta) dias corridos e, após o referido prazo, serão permanentemente excluídos da base de dados da plataforma. Assim, todas as suas publicações (fotos, vídeos, histórico de passeios, conversas privadas, etc) e atividades  (curtidas e comentários) serão excluídos, não sendo possível recuperar essas informações.
  <br />
  11 - Como notificaremos em caso de alteração nesta política?
  <br />
    Notificaremos você antes quando houver alteração das Políticas de Privacidade, dando a oportunidade de analisá-la antes de decidir continuar utilizando a plataforma.
  <br />
  12 - Como entrar em contato conosco em caso de dúvidas?
  <br />
    Você pode entrar em contato pelos canais de comunicação disponibilizados (Instagram, e-mail, site, etc).
  `,

  TERMS_OF_USE: `TERMOS DE USO DA PLATAFORMA
  <br />
  Seja bem-vindo (a) ao PetLog! Os Termos de Uso a seguir são os parâmetros que indicarão as diretrizes de uso da presente plataforma, informando os serviços oferecidos e as funcionalidades disponibilizadas.
  A PetLog inicialmente só será disponibilizada por meio de aplicativo para smartphones com sistema IOS e Android.
  Ao usar ou criar uma conta na PETLOG, você concorda inteiramente com estes Termos, valendo o mesmo como Acordo Vinculante entre a plataforma e o usuário.
  <br />
  1 - DA PLATAFORMA
  <br />
  1.1 - A presente plataforma trata-se de um ambiente digital voltado para Tutores de pets e seus pets com o intuito de criar um ambiente de compartilhamento e interação entre os usuários através das funcionalidades inerente às redes sociais, quais sejam: mensagens, fotos, vídeos, comentários, curtidas, etc.
  <br />
  1.2 - O usuário compartilhará com seus amigos e os usuários da plataforma fotos (temporárias e permanentes), vídeos (temporários e permanentes), textos, passeios (compartilhando dados de geolocalização, data, hora, tempo de passeio e percurso, podendo compartilhar imagens), podendo ainda criar/organizar de "encontros e eventos” virtuais a que aderir.
  <br />
  2 - DO CADASTRO DE USUÁRIOS E SEUS PETS
  <br />
  2.1 - O cadastramento dos usuários somente serão confirmados se preencherem todos os campos obrigatórios/indispensáveis ao cadastramento na plataforma PetLog,.
    <br />
    2.1.1 - O cadastro de usuários serão realizados em duas etapas: a primeira etapa com o cadastramento do usuário TUTOR e a segunda etapa para o cadastramentos de seu (s) respectivo (s) Pet (s).
    <br />
    2.1.2 - devendo preencher com informações verídicas, precisas e fidedignas (nome completo,  nome de usuário, senha de acesso, e-mail, data de nascimento, cidade, unidade federativa, etc). Os mesmos parâmetros se aplicam ao cadastramento do pet (nome, data de nascimento, sexo, raça, etc), que será vinculado diretamente ao seu tutor.
    <br />
    2.1.3 - É de inteira responsabilidade do usuário a atualização de seu cadastro na PetLog.
    <br />
    2.1.4 - No caso de serem informados dados incorretos ou inverídicos no ato do cadastramento, a PetLog reserva-se ao direito de excluir ou bloquear, de forma permanentemente ou temporária, o cadastro até que os erros que causaram a sanção sejam sanados.
    <br />
    2.1.5 - Os dados informados no cadastramento não poderão ser divulgados a terceiros, sendo de inteira responsabilidade do usuário a manutenção do sigilo de seu nome de usuário e senha.
  <br />
  2.2 - Efetuado com êxito o cadastramento, o Usuário poderá usufruir da totalidade dos serviços ofertados pela plataforma acessando com seu nome de usuário e senha informados no ato do cadastro.
  <br />
  3 - DAS OBRIGAÇÕES DO USUÁRIO
  <br />
  3.1 - Os usuários são obrigados a aceitar o presente TERMO DE USO e suas condições em sua totalidade para que tenham acesso à plataforma.
  <br />
  3.2 - Ficam obrigados todos os usuários a manterem entre si o bom relacionamento, prezando pelo respeito e integridade dos usuários e da plataforma, sob pena de, em não o fazendo, ter seu cadastro excluído da plataforma, sem prejuízo das demais responsabilidades cíveis, criminais e/ou administrativas que venham a ser tomadas.
  <br />
  3.3 - Aos usuários é terminantemente proibido o anúncio de produtos proibidos pela legislação vigente ou pelos órgãos reguladores competentes, bem como aqueles que violem os direitos de terceiros, implique em prática de ato ilícito e viole a legislação vigente.
  <br />
  3.4 - Aos usuários recaem a inteira responsabilidade pelos dados e informações publicadas.
  <br />
  3.5 - Aos usuários cabe o compromisso de respeitar a legislação brasileira vigente e o disposto neste Termo de Uso de Plataforma.
  <br />
  4- DAS OBRIGAÇÕES DA PetLog
  <br />
  4.1 - Obriga-se a PetLog a conceder acesso a todas as funcionalidades da plataforma para os usuários que preencherem todos os campos obrigatórios com informações verídicas no ato do cadastramento.
  <br />
  4.2 - Fica a PetLog obrigada a proteger por meio de armazenamento em servidores ou tecnologias de criptografia de dados todas as informações fornecidas por seus usuários.
    <br />
    4.2.1 - Não terá a PetLog responsabilidade pela reparação de prejuízos morais ou materiais causados pela captação ou apreensão  de má-fé de dados por parte de terceiros.
    <br />
    4.2.2 - Não terá a PetLog responsabilidade pela publicação de conteúdo em desconformidade com as diretrizes da plataforma que venham a afetar usuários ou terceiros, de forma direta ou indireta.
  <br />
  5 - DAS MODIFICAÇÕES DO PRESENTE TERMO DE USO
  <br />
    5.1 - A PetLog poderá, a qualquer tempo, alterar/modificar/atualizar as disposições presentes no presente Termo de Uso de da Plataforma, com a finalidade do constante aprimoramento de suas disposições/diretrizes, alteração na legislação ou funcionalidades disponibilizadas na plataforma, implantação de novas tecnologias ou, ainda, sempre que, a exclusivo critério da PetLog, tais alterações se façam necessárias, desde que comunique a seus usuários por meio do aplicativo, podendo concordar ou não o usuário com os novos Termos de Uso.
    <br />
    5.1.1 - Caso haja discordância do usuário com os novos Termos de Uso da Plataforma, o mesmo poderá contactar a PetLog pelos canais de comunicação disponibilizados pela mesma.
    <br />
    5.1.2 - Em caso de não aceitação dos novos Termos de Uso da Plataforma, o usuário terá seu acesso interrompido por tempo indeterminado até a aceitação dos novos termos.
    <br />
    5.2 - As modificações realizadas no presente Termo de Uso de Plataforma passarão a viger na data de sua implementação.
  <br />
  6 - DAS DISPOSIÇÕES GERAIS
  <br />
  6.1 - As postagens veiculadas pelos usuários ficam adstritas à utilização de textos e imagens.
  <br />
  7 - DA ACEITAÇÃO DO TERMO DE USO DE PLATAFORMA
  <br />
  7.1 - O usuário declara ter lido e aceito, em sua integralidade, as diretrizes, condutas e disposições contidas no presente TERMO DE USO DE PLATAFORMA.
  <br />
  8 -  DO FORO
  <br />
  8.1 - Para dirimir toda e  quaisquer controvérsias oriundas dos termos expostos neste TERMO DE USO DE PLATAFORMA serão solucionados de acordo com a legislação brasileira vigente, sendo competente o foro da cidade de Natal, Estado do Rio Grande do Norte, com a exclusão de qualquer outro por mais privilegiado que seja.
  `,
};
