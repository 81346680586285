import React from 'react';

import { Container, ContentContainer, Logo, Title, Text } from './styles';

interface Props {
  title: string;
  description?: string;
}

const Header: React.FC<Props> = ({ title, description }) => {
  return (
    <Container>
      <ContentContainer>
        <Logo src={require('../../assets/petlog.svg')} alt='PetLog' />
        <Title>{title}</Title>
        {description && <Text>{description}</Text>}
      </ContentContainer>
    </Container>
  );
};

export default Header;
