import * as Yup from 'yup';
import i18next from 'i18next';

import { EditCredentials } from '../../store/account/types';

const initialValues: EditCredentials = {
  password: '',
  confirmPassword: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, i18next.t('YUP_PASSWORD_REQUIRED'))
    .required( i18next.t('YUP_GENERAL_REQUIRED')),
  confirmPassword: Yup.string()
    .min(6, i18next.t('YUP_PASSWORD_REQUIRED'))
    .required(i18next.t('YUP_GENERAL_REQUIRED'))
    .oneOf([Yup.ref('password')], i18next.t('YUP_PASSWORD_CONFIRM')),
});

export default {
  initialValues,
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
};
